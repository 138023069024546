<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Projects</span>
          <v-spacer></v-spacer>
          <v-flex xl3 lg3 md3 sm3 xs12 pt-4>
                <v-text-field
                  class="rounded-xl"
                  outlined
                  dense
                  placeholder="Search"
                  v-model="searchKeyword"
                >
                </v-text-field>
              </v-flex>
          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-5>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="40%"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="font-family: kumbhBold"
                  dark
                  block
                  class="rounded-xl"
                  color="#005f32"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add Project
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="addcat" ref="addcat">
                  <v-layout wrap>
                    <v-flex
                      xs12
                      pa-4
                      text-left
                      align-self-center
                      style="background: #005f32 !important"
                    >
                      <span
                        class="kumbhBold"
                        style="color: #ffffff; font-size: 20px"
                        >Add Project</span
                      >
                    </v-flex>
                    <v-flex xs12 align-self-center text-left pt-8 px-5>
                      <v-text-field
                        v-model="projectName"
                        label="Project Name"
                        :rules="[rules.required]"
                        required
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 align-self-center text-left pa-2 px-5>
                      <v-select
                        v-model="department"
                        :items="departmentList"
                        item-text="deptName"
                        :rules="[rules.required]"
                        required
                        item-value="_id"
                        hide-details
                        outlined
                        dense
                        label="Choose Department"
                      >
                      </v-select>
                    </v-flex>


                    <v-flex xs12 align-self-center text-left pa-2 px-5>
                      <v-select
                        v-model="divisiontName"
                        :items="divisionlist"
                        item-text="divisiontName"
                        :rules="[rules.required]"
                        required
                        item-value="_id"
                        hide-details
                        outlined
                        dense
                        label="Choose Division"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 align-self-center text-left pa-2 px-5>
                      <v-text-field
                        v-model="location"
                        label="Project Location"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 align-self-center text-left pa-2 px-5>
                      <v-textarea
                        v-model="description"
                        label="Project Description"
                        hide-details
                        outlined
                        dense
                      ></v-textarea>
                    </v-flex>
                  </v-layout>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="error"
                      tile
                      outlined
                      @click="dialogclose(), (projectName = null)"
                    >
                      Close
                    </v-btn>
                    &nbsp;
                    <v-btn
                      color="#005f32"
                      dark
                      tile
                      :disabled="!addcat"
                      @click="addProject()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex
            xs12
            md6
            sm6
            lg4
            xl3
            align-self-center
            v-for="(item, i) in projectList"
            :key="i"
            fill-height
            pa-4
          >
            <v-card tile color="#F5F5DC">
              <v-layout wrap justify-end py-4>
                <v-flex xs12 align-self-start text-center pa-3>
                  <span class="lheight" style="font-family: kumbhBold">{{
                    item.projectName
                  }}</span>
                </v-flex>
                <v-flex
                  xs12
                  align-self-center
                  text-left
                  pa-1
                  px-3
                  v-if="item.department"
                >
                  <span class="kumbhMedium">
                    Department  :
                    <span>
                      {{ item.department[0].deptName }}
                    </span>
                  </span>
                </v-flex>
                <v-flex
                  xs12
                  align-self-center
                  text-left
                  pa-1
                  px-3
                  v-if="item.division"
                >
                  <span class="kumbhMedium">
                    Division :
                    <span v-if="item.division.divisiontName">
                      {{ item.division.divisiontName }}
                    </span>
                  </span>
                </v-flex>
                <v-flex
                  xs12
                  align-self-center
                  text-left
                  pa-1
                  px-3
                  v-if="item.location"
                >
                  <span class="kumbhMedium">
                    Location :
                    <span v-if="item.location">
                      {{ item.location }}
                    </span>
                  </span>
                </v-flex>
                <v-flex
                  xs12
                  align-self-center
                  text-left
                  pa-1
                  px-3
                  v-if="item.description"
                >
                  <span class="kumbhMedium">
                    Description :
                    <span v-if="item.description">
                      {{ item.description.slice(0, 15) }}
                      <br />
                      <v-dialog v-model="item.view" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <span class="text-right">
                            <v-btn
                            small
                            style="font-family: kumbhBold"
                            class="text-none"
                            text
                            plain
                            :ripple="false"
                            depressed
                            dark
                            title="View more"
                            v-on="on"
                            v-bind="attrs"
                            color="#005f32"
                          >
                            <span class="vhover"> View more.. </span>
                          </v-btn>
                          </span>
                        </template>
                        <v-card class="pa-4 pt-8">
                          <v-card-text>
                            <span
                              style="
                                color: #000;
                                font-size: 20px;
                                border-bottom: 1px solid #000;
                              "
                              class="kumbhRegular"
                            >
                              Description
                            </span>
                          </v-card-text>
                          <v-card-text>
                            <span style="color: #000" class="kumbhMedium">
                              {{ item.description }}
                            </span>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </span>
                  </span>
                </v-flex>

                <v-flex xs6 sm4 md2 pa-2>
                  <v-dialog persistent v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: kumbhBold"
                        class="mr-2"
                        fab
                        outlined
                        depressed
                        dark
                        title="Delete"
                        v-on="on"
                        v-bind="attrs"
                        color="#005f32"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          align-self-center
                          pa-4
                          style="background: #005f32"
                          text-left
                        >
                          <span class="kumbhBold" style="color: #ffffff"
                            >Delete Confirmation</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-card-title
                        >Are you sure you want to delete this
                        Project?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          tile
                          dark
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        &nbsp;
                        <v-btn
                          color="#005f32"
                          outlined
                          tile
                          @click="deleteItem(item)"
                          >Delete</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex xs6 sm4 md2 pa-2>
                  <v-btn
                    color="#005f32"
                    style="font-family: kumbhBold"
                    small
                    dark
                    outlined
                    title="Edit"
                    depressed
                    fab
                    @click="editcat(item)"
                  >
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-dialog persistent v-model="editdialog" max-width="40%">
            <v-card tile>
              <v-layout wrap>
                <v-flex
                  xs12
                  pa-4
                  text-left
                  align-self-center
                  style="background: #005f32 !important"
                >
                  <span
                    class="kumbhBold"
                    style="color: #ffffff; font-size: 20px"
                    >Edit Project</span
                  >
                </v-flex>
                <v-flex xs12 pa-2 px-5 pt-8 text-left>
                  <v-text-field
                    v-model="editingitem.projectName"
                    label="Project Name"
                    :rules="[rules.required]"
                    outlined
                    hide-details
                    dense
                    required
                  >
                  </v-text-field>
                </v-flex>

                <v-flex xs12 align-self-center text-left pa-2 px-5>
                  <v-select
                    v-model="editingitem.department"
                    :items="departmentList"
                    item-text="deptName"
                    :rules="[rules.required]"
                    required
                    item-value="_id"
                    hide-details
                    outlined
                    dense
                    label="Choose Department"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 align-self-center text-left pa-2 px-5>
                  <v-text-field
                    v-model="editingitem.location"
                    label="Project Location"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 align-self-center text-left pa-2 px-5>
                  <v-textarea
                    v-model="editingitem.description"
                    label="Project Description"
                    hide-details
                    outlined
                    dense
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  tile
                  outlined
                  @click="(editdialog = false), getData()"
                >
                  Close
                </v-btn>
                &nbsp;
                <v-btn
                  v-if="!g"
                  color="#005f32"
                  dark
                  tile
                  @click="edit(editingitem._id)"
                >
                  Update
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      divisiontName: null,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      searchKeyword:"",
      rules: {
        required: (value) => !!value || "Required.",
      },
      projectList: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      projectName: null,
      projectHead: null,
      departmentList: [],
      department: null,
      location: null,
      divisionlist: [],
      description: null,
    };
  },
  components: {
    // ImageComp,
  },

  watch: {
    department() {
      this.getDiv();
    },
    searchKeyword(){
      this.getData()
    }
   
  },
  beforeMount() {
    this.getDept();
    this.getData();
    // this.getDiv();
  },

  methods: {
    getDept() {
      this.appLoading = true;
      axios({
        url: "/department/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.departmentList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/projects/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/projects/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params:{searchKeyword:this.searchKeyword}
      })
        .then((response) => {
          this.appLoading = false;
          this.projectList = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    addProject() {
      var data = {};
      data["projectName"] = this.projectName;
      data["department"] = this.department;
      data["location"] = this.location;
      data["description"] = this.description;
      data["divisiontName"] = this.divisiontName;
      // data["projectHead"] = this.projectHead;
      axios({
        url: "/projects/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.projectName = null;
            this.department = null;
            this.location = null;
            this.description = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    getDiv() {
      // var data = {};
      // data["departmentId"] = this.departmentId;
      this.appLoading = true;
      axios({
        url: "/division/list",
        method: "GET",
        // data: data,
        params:{
          departmentId: this.department,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.divisionlist = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["projectName"] = this.editingitem.projectName;
      data["department"] = this.editingitem.department;
      data["location"] = this.editingitem.location;
      data["description"] = this.editingitem.description;
      axios({
        url: "/projects/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
